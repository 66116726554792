import { IBuildingCreate, IBuildingRead, IBuildingUpdate } from "../entities/Building";
import { IBuildingAreaRead } from "../entities/BuildingArea";
import { IBuildingAttachmentRead } from "../entities/BuildingAttachment";
import { IDocumentationRead } from "../entities/Documentation";
import { ApiResponse, ApiService } from "./ApiService";
import { fetchTypes, HTTPMethod } from "./Connection";

interface IImageUpload {
    imageFile: File;
}

export class BuildingApi extends ApiService<IBuildingRead, IBuildingCreate | IBuildingUpdate> {
    constructor() {
        super(`/buildings`);
    }

    getBuildingAreas(id: IBuildingRead["id"]): Promise<ApiResponse<IBuildingAreaRead[]>> {
        return this.get<IBuildingAreaRead[]>(`/${id}/areas`);
    }

    getFiles(id: IBuildingRead["id"]): Promise<ApiResponse<IBuildingAttachmentRead[]>> {
        return this.get<IBuildingAttachmentRead[]>(`/${id}/attachments`, { excludedTypes: "documentationitem" });
    }

    getDocumentations(id: IBuildingRead["id"]): Promise<ApiResponse<IDocumentationRead[]>> {
        return this.get<IDocumentationRead[]>(`/${id}/documentations`);
    }

    deactivate(id: IBuildingRead["id"]): Promise<ApiResponse<never>> {
        return this.invoke(fetchTypes.fetch, {
            route: `/${id}/delete`,
            method: HTTPMethod.PUT,
            body: { isActive: false },
        });
    }

    uploadLogo(id: IBuildingRead["id"], body: IImageUpload): Promise<ApiResponse<IBuildingRead>> {
        const form = new FormData();
        form.append("imageFile", body.imageFile);
        return this.invoke<IBuildingRead, fetchTypes.fetchUpload>(fetchTypes.fetchUpload, {
            route: `/${id}/upload-logo`,
            body: form,
            method: HTTPMethod.POST,
        });
    }

    removeLogo(id: IBuildingRead["id"]): Promise<ApiResponse<IBuildingRead>> {
        return this.invoke<IBuildingRead>(fetchTypes.fetch, {
            route: `/${id}/remove-logo`,
            method: HTTPMethod.PUT,
            body: {},
            credentials: "include",
            mode: "cors",
        });
    }

    removeSafetyCheckRelevantData(id: IBuildingRead["id"]): Promise<ApiResponse<void>> {
        return this.invoke(fetchTypes.fetch, {
            route: `/${id}/remove-safety-check-relevant-data`,
            method: HTTPMethod.POST,
            credentials: "include",
        });
    }
}
