import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { buildingService } from "../../../services/BuildingService";
import { Button } from "@abs-safety/lock-book-web-ui";
import { getController } from "../../../stores/controller/ControllerFactory";
import { BuildingController } from "../BuildingController";

interface TrainingAdministrationProps {
    selectedBuildingId: number;
}

const TrainingAdministration: FunctionComponent<TrainingAdministrationProps> = (props: TrainingAdministrationProps) => {
    const building = buildingService.get(props.selectedBuildingId);
    const { controller } = getController(BuildingController);

    const handleDelete = () => {
        const confirmDelete = window.confirm(
            `Warnung: Diese Aktion löscht unwiderruflich alle ABS-Seriennummern und ABS-Plakettennummern die mit dem Objekt "${building?.name}" assoziiert sind. ID: ${building?.id}.`
        );

        if (confirmDelete) {
            controller.removeSafetyCheckRelevantData(props.selectedBuildingId);
        }
    };

    return (
        <div>
            <>
                <h5>Safetycheck</h5>
                <Button color="decline" size="small" variant="outline">
                    <button
                        onClick={handleDelete}
                        style={{
                            backgroundColor: "red",
                            color: "white",
                            padding: "10px 20px",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                    >
                        Plaketten und Seriennummern löschen
                    </button>
                </Button>
            </>
        </div>
    );
};

export default observer(TrainingAdministration);
