import { action, computed, makeObservable, observable, reaction } from "mobx";
import { ApiResponse } from "../../api/ApiService";
import { AreaApi } from "../../api/AreaApi";
import { BuildingApi } from "../../api/BuildingApi";
import { IArticleOverviewItemDocu } from "../../api/DocumentationApi";
import { IBuildingRead } from "../../entities/Building";
import { IBuildingAreaRead } from "../../entities/BuildingArea";
import { IBuildingAttachmentRead, IBuildingAttachmentUpdate } from "../../entities/BuildingAttachment";
import {
    DocumentationType,
    IDocumentationBase,
    IDocumentationCreateCertificate,
    IDocumentationRead,
    IDocumentationUpdate,
} from "../../entities/Documentation";
import { IDocumentationSharingHashRead, IDocumentationSharingHashWrite } from "../../entities/DocumentationSharingHash";
import { ITransferKeyBase } from "../../entities/TransferKey";
import { areaService } from "../../services/AreaService";
import { attachmentService } from "../../services/AttachmentService";
import { buildingService } from "../../services/BuildingService";
import { dataLayerService } from "../../services/DataLayerService";
import { documentationService } from "../../services/DocumentationService";
import { documentationSharingHashService } from "../../services/DocumentationSharingHashService";
import { documentService } from "../../services/DocumentService";
import { transferKeyService } from "../../services/TransferKeyService";
import { session } from "../../session/Session";
import { SuperControllerStore } from "../../stores/controller/SuperControllerStore";

type waitingForKeys =
    | "loadBuildingAreas"
    | "loadBuildingContributors"
    | "loadBuildingAttachments"
    | "loadFiles"
    | "reloadUrl"
    | "createBuildingArea"
    | "deleteBuildingArea"
    | "deleteFile"
    | "loadBuilding"
    | "loadDocumentations"
    | "loadCurrentDocumentations"
    | "deleteDocumentation"
    | "uploadBuildingAttachment"
    | "updateFile"
    | "createDocumentation"
    | "uploadBuildingImage"
    | "deleteBuildingImage"
    | "loadDocumentationSharingHashes"
    | "updateBuilding"
    | "updateDocumentation"
    | "loadDocumentationLinks"
    | "createSharingHash"
    | "loadNewestSharingHash"
    | "createTransferKey"
    | "getTransferKey"
    | "removeSafetyCheckRelevantData";

export class BuildingController extends SuperControllerStore<waitingForKeys> {
    static controllerName = "BuildingController";

    buildingApi: BuildingApi = new BuildingApi(); // TODO: remove unused
    buildingAreaApi: AreaApi = new AreaApi(); // TODO: remove unused
    fetchedDocumentationAmount = 0;
    initializedLinks = false;

    get building(): IBuildingRead | undefined {
        return buildingService.get(this.currentId);
    }

    get transferKey(): ITransferKeyBase | undefined {
        return transferKeyService.list.find((res) => res.building === `/buildings/${this.currentId}`);
    }

    get areas(): IBuildingAreaRead[] {
        return areaService.list
            .filter((area) => area.buildingId === this.currentId && area.isDeleted === false)
            .sort((a, b) => {
                return b.id - a.id;
            });
    }

    get attachments(): IBuildingAttachmentRead[] {
        return attachmentService.list.filter((attachment) => {
            return (
                attachment.buildingId === this.currentId &&
                (attachment.buildingAreaId ?? false) === false &&
                (attachment.documentationId ?? false) === false &&
                (attachment.documentationItemId ?? false) === false
            );
        });
    }

    get documentations(): IDocumentationRead[] {
        return documentationService.list
            .filter((item) => {
                item.buildingId === this.currentId;
                if (item.buildingId === this.currentId && item.isCompleted) {
                    return true;
                }
            })
            .sort((a, b) => b.id - a.id);
    }

    get docsInitialized(): boolean {
        return this.initializedHashes && this.initializedLinks;
    }

    private initializedHashes = false;

    constructor() {
        super(session, {
            all: false,
            deleteBuildingImage: false,
            uploadBuildingImage: false,
            createBuildingArea: false,
            createDocumentation: false,
            deleteBuildingArea: false,
            deleteFile: false,
            loadBuilding: false,
            loadDocumentations: false,
            loadCurrentDocumentations: false,
            deleteDocumentation: false,
            loadBuildingAreas: false,
            loadBuildingAttachments: false,
            loadBuildingContributors: false,
            loadFiles: false,
            reloadUrl: false,
            updateFile: false,
            uploadBuildingAttachment: false,
            loadDocumentationSharingHashes: false,
            updateBuilding: false,
            loadDocumentationLinks: false,
            createSharingHash: false,
            loadNewestSharingHash: false,
            updateDocumentation: false,
            createTransferKey: false,
            getTransferKey: false,
            removeSafetyCheckRelevantData: false,
        });

        makeObservable<BuildingController, "initializedHashes">(this, {
            building: computed,
            transferKey: computed,
            areas: computed,
            attachments: computed,
            documentations: computed,
            fetchedDocumentationAmount: observable,
            initializedLinks: observable,
            initializedHashes: observable,
            docsInitialized: computed,
            uploadBuildingAttachment: action,
        });
    }

    init(id: number): void {
        this.currentId = id;
        dataLayerService.lastBuildingId = id;
        dataLayerService.emitHistory(location, dataLayerService.dataLayer, "building");
        this.register(
            reaction(
                () => this.documentations,
                () => {
                    this.loadDocumentationLinks();
                    this.loadDocumentationSharingHashes();
                }
            )
        );

        this.usePageData(async () => {
            await buildingService.getOrFetch(id);

            if (id > 0) {
                await Promise.all([this.loadBuildingAreas(), this.loadFiles(), this.loadBuilding(id)]);
            }
            /* make sure, tab Assembly is always default (when switching between buildings, where tab Maintenance was visited and remains default, until another tab is selected*/
            buildingService.setInMaintenanceTab(false);
        });
    }

    deleteBuildingImage(): void {
        if (this.currentId === 0) {
            throw Error("Cannot delete BuildingImage, before buildingId is stored");
        }

        this.resolveAsAction({
            promise: () => buildingService.removeBuildingImage(this.currentId),
            waitingForKey: ["deleteBuildingImage"],
        });
    }

    loadBuildingAreas(): void {
        if (this.currentId === 0) {
            throw Error("Cannot create BuildingArea, before buildingId is stored");
        }
        this.resolveAsAction({
            promise: () => areaService.fetchAreasByBuilding(this.currentId),
            waitingForKey: ["loadBuildingAreas"],
        });
    }

    async loadFiles(reloadUrl?: boolean): Promise<void> {
        if (this.currentId === 0) {
            throw Error("Cannot create BuildingArea, before buildingId is stored");
        }
        this.resolveAsAction({
            promise: () => attachmentService.fetchByBuilding(this.currentId),
            waitingForKey: reloadUrl === true ? "reloadUrl" : "loadFiles",
        });
    }

    createBuildingArea(name: string): void {
        if (this.currentId === 0) {
            throw Error("Cannot create BuildingArea, before buildingId is stored");
        }

        this.resolveAsAction({
            promise: () =>
                areaService.create({
                    name,
                    buildingId: this.currentId,
                }),
            waitingForKey: ["createBuildingArea"],
        });
    }

    deleteBuildingArea(id: number): void {
        this.resolveAsAction({
            promise: () => areaService.delete(id),
            waitingForKey: ["deleteBuildingArea"],
        });
    }

    deleteFile(id: number): void {
        if (this.currentId === 0) {
            throw Error("Cannot delete BuildingAttachment, before buildingId is stored");
        }

        this.resolveAsAction({
            promise: () => attachmentService.delete(id),
            waitingForKey: ["deleteFile"],
        });
    }

    loadBuilding(buildingId: number): void {
        this.resolveAsAction({
            promise: () => buildingService.fetch(buildingId),
            waitingForKey: ["loadBuilding"],
        });
    }

    loadDocumentations(): void {
        if (this.currentId === 0) {
            throw Error("Cannot load articles, before buildingAreaId is stored");
        }
        this.resolveAsAction({
            promise: () => documentationService.fetchByBuildingId(this.currentId),
            waitingForKey: "loadDocumentations",
        });
    }

    loadCurrentDocumentations(id: number): void {
        if (id === 0) {
            throw Error("Cannot load articles, before buildingAreaId is stored");
        }
        this.resolveAsAction({
            promise: () => documentationService.fetchByBuildingId(id),
            waitingForKey: "loadCurrentDocumentations",
        });
    }

    async uploadBuildingAttachment(files: File | File[]): Promise<void> {
        if (this.currentId === 0) {
            throw Error("Cannot upload File, before buildingId is stored");
        }

        if (!Array.isArray(files)) {
            files = [files];
        }

        for (const file of files) {
            await this.resolveAsAction({
                promise: () => attachmentService.uploadBuildingAttachment(this.currentId, { imageFile: file }),
                waitingForKey: ["uploadBuildingAttachment"],
            });
        }
    }

    updateFile(
        buildingAttachmentId: number,
        data: IBuildingAttachmentUpdate
    ): Promise<ApiResponse<IBuildingAttachmentRead>> {
        return this.resolveAsAction({
            promise: () => attachmentService.update(buildingAttachmentId, data),
            waitingForKey: ["updateFile"],
        });
    }

    createDocumentation(
        buildingAreaId: number,
        type: DocumentationType,
        name: IDocumentationBase["name"]
    ): Promise<ApiResponse<IDocumentationRead>> {
        return this.resolveAsAction({
            promise: () => documentationService.createByArea(buildingAreaId, { type, name }),
            waitingForKey: ["createDocumentation"],
        });
    }

    deleteDocumentation(id: number): Promise<ApiResponse<IArticleOverviewItemDocu>> {
        return this.resolveAsAction({
            promise: () => documentationService.deactivate(id),
            waitingForKey: ["deleteDocumentation"],
        });
    }

    updateDocumentation(data: IDocumentationUpdate): void {
        if (this.currentId === 0) {
            throw Error("Cannot update documentation, before documentationId is stored");
        }

        this.resolveAsAction({
            promise: () => documentationService.update(this.currentId, data),
            waitingForKey: "updateDocumentation",
        });
    }

    uploadBuildingImage(file: File): void {
        if (this.currentId === 0) {
            throw Error("Cannot upload File, before buildingId is stored");
        }

        this.resolveAsAction({
            promise: () => buildingService.uploadBuildingImage(this.currentId, file),
            waitingForKey: "uploadBuildingImage",
        });
    }

    createDocumentationSharingHash(
        body: IDocumentationSharingHashWrite
    ): Promise<ApiResponse<IDocumentationSharingHashRead>> {
        return this.resolveAsAction({
            promise: () => documentationSharingHashService.createDocumentationSharingHash(body),
            waitingForKey: "createSharingHash",
            action: (response) => {
                return response;
            },
        });
    }

    loadDocumentationSharingHashes(): void {
        this.resolveAsAction({
            promise: () =>
                Promise.all(
                    this.documentations.map((docu) =>
                        documentationSharingHashService.getDocumentationSharingHashes(docu.id)
                    )
                ),
            waitingForKey: ["loadDocumentationSharingHashes"],
            action: (result) => {
                this.initializedHashes = true;
                return result;
            },
        });
    }

    loadDocumentationLinks(): void {
        this.resolveAsAction({
            promise: () =>
                Promise.all(this.documentations.map((docu) => documentService.fetchByDocumentation(docu.id))),
            waitingForKey: ["loadDocumentationLinks"],
            action: (result) => {
                this.initializedLinks = true;
                return result;
            },
        });
    }

    createCertificate = (data: IDocumentationCreateCertificate, id: number) => {
        if (id === 0) {
            throw Error("Cannot update Documentation, before documentationId is stored");
        }
        return this.resolveAsAction({
            promise: () => documentationService.createCertificate(id, data),
            waitingForKey: "updateDocumentation",
            action: (response) => {
                // update building.projectNumber
                buildingService.mergeList({
                    ...this.building,
                    projectNumber: data.projectNumber,
                } as IBuildingRead);
                return response;
            },
        });
    };

    createTransferKey(currentId: number): Promise<ApiResponse<ITransferKeyBase>> {
        return this.resolveAsAction({
            promise: () => transferKeyService.create({ building: `buildings/${currentId}` }),
            waitingForKey: "createTransferKey",
            action: (response) => {
                return response;
            },
        });
    }

    removeSafetyCheckRelevantData(buildingId: number): void {
        this.resolveAsAction({
            promise: () => buildingService.removeSafetyCheckRelevantData(buildingId),
            waitingForKey: "removeSafetyCheckRelevantData",
        });
    }
}
